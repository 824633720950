import { Svg } from '@pancakeswap/uikit'

// NOTE: Temporary save icon here. Will move to uikit when the feature is done

const DisabledIcon = (props) => (
  <Svg id="arrow_loading" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5663V5.9898C9.00002 6.04324 9.01527 6.09557 9.04395 6.14066C9.07264 6.18575 9.11358 6.22174 9.16197 6.2444C9.21037 6.26707 9.26422 6.27547 9.31723 6.26864C9.37023 6.2618 9.42019 6.24 9.46125 6.2058L12.1163 3.99405C12.2513 3.88155 12.2513 3.67455 12.1163 3.56205L9.46125 1.3503C9.42019 1.3161 9.37023 1.2943 9.31723 1.28747C9.26422 1.28063 9.21037 1.28904 9.16197 1.3117C9.11358 1.33437 9.07264 1.37035 9.04395 1.41544C9.01527 1.46053 9.00002 1.51286 9 1.5663ZM5.62746 5.46444C6.60027 4.73532 7.78313 4.34099 8.99885 4.34052V3.21552C7.53993 3.21577 6.12036 3.68869 4.95277 4.56345C3.78519 5.4382 2.93244 6.6677 2.52227 8.06777C2.11209 9.46785 2.16657 10.9631 2.67755 12.3296C3.18852 13.6962 4.12848 14.8603 5.35663 15.6478C6.58478 16.4353 8.03501 16.8036 9.49008 16.6977C10.9451 16.5918 12.3267 16.0173 13.4279 15.0603C14.5291 14.1032 15.2905 12.8152 15.5982 11.3891C15.9059 9.96297 15.7432 8.47556 15.1346 7.14965C15.0687 7.02024 14.9553 6.92134 14.8181 6.87365C14.6809 6.82596 14.5306 6.83317 14.3986 6.89378C14.2666 6.9544 14.1632 7.06371 14.11 7.19884C14.0568 7.33397 14.0579 7.48444 14.1131 7.61877C14.6203 8.72364 14.7559 9.9631 14.4996 11.1515C14.2433 12.3399 13.6088 13.4133 12.6913 14.2108C11.7737 15.0084 10.6225 15.4872 9.41 15.5756C8.19749 15.664 6.98898 15.3572 5.96547 14.7011C4.94197 14.0451 4.15857 13.0751 3.7326 11.9364C3.30664 10.7977 3.26104 9.55173 3.60264 8.38499C3.94424 7.21824 4.65465 6.19357 5.62746 5.46444Z"
        fill="white"
      />
    </svg>
  </Svg>
)

const Icon = ({ disabled, ...props }) =>
  disabled ? (
    <DisabledIcon {...props} />
  ) : (
    <Svg id="arrow_loading" viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fill="white"
        d="M16.2751 7.78995C13.932 5.44681 10.133 5.44681 7.78986 7.78995C7.02853 8.55128 6.51457 9.4663 6.24798 10.4351C6.24473 10.4499 6.24114 10.4646 6.23719 10.4793C6.17635 10.7064 6.12938 10.9339 6.09577 11.161C5.83159 12.9457 6.39255 14.7026 7.52624 15.9944C7.61054 16.0901 7.69842 16.1838 7.78986 16.2752C8.08307 16.5685 8.39909 16.825 8.7322 17.0448C9.25533 17.3892 9.84172 17.6568 10.4798 17.8278C10.7386 17.8971 10.9979 17.9484 11.2565 17.9825C12.9537 18.2061 14.6187 17.6866 15.8747 16.6415C16.0123 16.5265 16.1459 16.4044 16.2751 16.2752C16.2848 16.2655 16.2947 16.2561 16.3047 16.2469C17.0123 15.531 17.5491 14.627 17.8283 13.5851C17.9712 13.0517 18.5196 12.7351 19.053 12.878C19.5865 13.021 19.9031 13.5693 19.7602 14.1028C19.3141 15.7676 18.3745 17.1684 17.1409 18.1899C16.1883 18.9822 15.0949 19.5189 13.9515 19.8002C11.8607 20.3147 9.6028 19.9749 7.7328 18.7809C7.06855 18.3579 6.47841 17.8432 5.97519 17.2589C5.12341 16.2738 4.55173 15.1302 4.26015 13.9324C4.01698 12.9416 3.96104 11.8931 4.12168 10.8379C4.36697 9.20484 5.1183 7.63309 6.37564 6.37574C9.49984 3.25154 14.5652 3.25154 17.6894 6.37574L18.2332 6.91959L18.2337 5.49951C18.2338 5.05769 18.5921 4.69964 19.034 4.69979C19.4758 4.69995 19.8338 5.05825 19.8337 5.50007L19.8325 9.03277L19.8322 9.8325L19.0325 9.83249L18.9401 9.83249C18.8146 9.85665 18.6854 9.85665 18.5599 9.83248L15.5005 9.83245C15.0587 9.83245 14.7005 9.47427 14.7005 9.03244C14.7005 8.59062 15.0587 8.23245 15.5005 8.23245L16.7176 8.23246L16.2751 7.78995Z"
      />
      <defs>
        <path
          id="arrow"
          stroke="none"
          fill="none"
          d="M16.2751 7.78995C13.932 5.44681 10.133 5.44681 7.78986 7.78995C7.02853 8.55128 6.51457 9.4663 6.24798 10.4351C6.24473 10.4499 6.24114 10.4646 6.23719 10.4793C6.17635 10.7064 6.12938 10.9339 6.09577 11.161C5.83159 12.9457 6.39255 14.7026 7.52624 15.9944C7.61054 16.0901 7.69842 16.1838 7.78986 16.2752C8.08307 16.5685 8.39909 16.825 8.7322 17.0448C9.25533 17.3892 9.84172 17.6568 10.4798 17.8278C10.7386 17.8971 10.9979 17.9484 11.2565 17.9825C12.9537 18.2061 14.6187 17.6866 15.8747 16.6415C16.0123 16.5265 16.1459 16.4044 16.2751 16.2752C16.2848 16.2655 16.2947 16.2561 16.3047 16.2469C17.0123 15.531 17.5491 14.627 17.8283 13.5851C17.9712 13.0517 18.5196 12.7351 19.053 12.878C19.5865 13.021 19.9031 13.5693 19.7602 14.1028C19.3141 15.7676 18.3745 17.1684 17.1409 18.1899C16.1883 18.9822 15.0949 19.5189 13.9515 19.8002C11.8607 20.3147 9.6028 19.9749 7.7328 18.7809C7.06855 18.3579 6.47841 17.8432 5.97519 17.2589C5.12341 16.2738 4.55173 15.1302 4.26015 13.9324C4.01698 12.9416 3.96104 11.8931 4.12168 10.8379C4.36697 9.20484 5.1183 7.63309 6.37564 6.37574C9.49984 3.25154 14.5652 3.25154 17.6894 6.37574L18.2332 6.91959L18.2337 5.49951C18.2338 5.05769 18.5921 4.69964 19.034 4.69979C19.4758 4.69995 19.8338 5.05825 19.8337 5.50007L19.8325 9.03277L19.8322 9.8325L19.0325 9.83249L18.9401 9.83249C18.8146 9.85665 18.6854 9.85665 18.5599 9.83248L15.5005 9.83245C15.0587 9.83245 14.7005 9.47427 14.7005 9.03244C14.7005 8.59062 15.0587 8.23245 15.5005 8.23245L16.7176 8.23246L16.2751 7.78995Z"
        />
        <clipPath id="arrow-clip">
          <use xlinkHref="#arrow" />
        </clipPath>
      </defs>
      <g clipPath="url(#arrow-clip)">
        <circle
          cx="12"
          cy="12"
          r="5"
          transform="rotate(365,12,12)"
          fill="none"
          stroke="#7D9CD1"
          strokeWidth="16"
          strokeDasharray="30"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="0;-30"
            begin="arrow_loading.click; 0.7s"
            repeatCount="indefinite"
            dur="6.3s"
          />
        </circle>
      </g>
      <use xlinkHref="#arrow" />
      <animateTransform
        id="transform_0"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 0 0"
        to="-10 0 0"
        dur="0.07s"
        begin="arrow_loading.click;"
        repeatCount="1"
      />
      <animateTransform
        id="transform_1"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="-45 0 0"
        to="390 0 0"
        dur="0.6s"
        begin="transform_0.end"
        repeatCount="1"
      />
      <animateTransform
        id="transform_2"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="390 0 0"
        to="360 0 0"
        dur="0.15s"
        begin="transform_1.end"
        repeatCount="1"
      />
    </Svg>
  )

export default Icon
